<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark mmlc-nav">
      <a href="/" class="navbar-brand">Murraylands <span>Cycling</span> Club</a>
      <div class="navbar-nav mr-auto" id="mmlcNav">
        <li class="nav-item">
          <router-link to="/home" class="nav-link">
            <i class="fa-solid fa-home"></i> Home
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" class="nav-link">
            <i class="fa-solid fa-info-circle"></i> About
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/membership" class="nav-link">
            <i class="fa-solid fa-id-card"></i> Membership
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kit" class="nav-link">
            <i class="fa-solid fa-shopping-cart"></i> Kit Orders
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/contact" class="nav-link">
            <i class="fa-solid fa-envelope"></i> Contact
          </router-link>
        </li>
      </div>
      <div v-if="!currentUser" class="navbar-nav ms-auto">
        <!--
        <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <i class="fa-solid fa-user-plus"></i> Register
          </router-link>
        </li>
        -->
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <i class="fa-solid fa-user"></i> Login
          </router-link>
        </li>
      </div>
      <div v-if="currentUser" class="navbar-nav ms-auto">
        <li class="nav-item">
          <router-link v-if="currentUser" to="/members" class="nav-link">Members</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <i class="fa-solid fa-right-from-bracket"></i> LogOut
          </a>
        </li>
      </div>
    </nav>
      <router-view />
    <div class="container-fluid footer">
  <div class="row">
    <div class="col-sm-4 footer-left">
      <a href="https://www.facebook.com/groups/621274398881572/?ref=share_group_link" target="new">
        <br>
        <i class="fa fa-facebook fa-2x" aria-hidden="true"></i> Like us on Facebook
      </a>
    </div>
    <div class="col-sm-4 footer-mid">
      <span>copyright &copy; 2021</span><br>
      Murraylands Cycling Club<br>
      <span>all rights reserved</span>
    </div>
    <div class="col-sm-4 footer-right">
      <span>website design by</span><br>
      <a href="https://www.webineering.com.au/" target="new">The Webineering Workshop</a>
    </div>
  </div>
</div>
  </div>
</template>
<script>
export default {
computed: {

    currentUser() {
      return this.$store.state.auth.user;
    },
    isMember() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MEMBER');
      }
      return false;
    },
    isCommittee() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_COMMITTEE');
      }
      return false;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }
      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>

.navbar {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.mmlc-nav {
	background-color: #2d96ff;
	padding-right: 10px;
}
.navbar-brand {
	padding-left: 30px;
	padding-right: 80px;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
}
.navbar-brand span {
	color: #333;
}
li.nav-item {
	padding-right: 20px;
	color: #fff;
}
li.nav-item a {
	color: #fff;
	border: solid #2d96ff 1px;
}
li.nav-item a:hover {
	color: #333;
	border: solid #333 1px;
  cursor: pointer;
}

.active {
	background-color: #333;
	color: #fff;
}
.active:hover{
	background-color: #999;
	color: #fff;
}
.footer {
	background-color: #333;
	color: #fff;
}
.footer-left {
	padding: 30px;
	padding-left: 70px;
	color:#4d87bd;
}
.footer-left i {
	margin-right: 10px;
}
.footer-left a:hover {
	color: #fff;
}
.footer-mid {
	padding: 30px;
	color:#fff;
	border-left: solid #fff 1px;
	border-right: solid #fff 1px;
	text-align: center;
}
.footer-mid span {
	color: #2d96ff;
	font-size: 0.75rem;
}
.footer-right {
	padding: 30px;
	text-align: center;
	color:#fff;
}
.footer-right span {
	color: #b6c2ce;
	font-size: 0.75rem;
}
.footer-right a {
	color: #fff;
}
.footer-right a:hover {
	color: #2d96ff;
}
</style>

