<template>
  <!-- Carousel -->
<div id="mlcc_carousel" class="carousel slide" data-bs-ride="carousel">

  <!-- Indicators/dots -->
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="2"></button>
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="3"></button>
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="4"></button>
    <button type="button" data-bs-target="#mlcc_carousel" data-bs-slide-to="5"></button>
  </div>

  <!-- The slideshow/carousel -->
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="../assets/images/slides/slide1.jpg" alt="First slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../assets/images/slides/slide5.jpg" alt="MLCC members at the superdome">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../assets/images/slides/slide2.jpg" alt="Second slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../assets/images/slides/slide6.jpg" alt="MLCC members at the superdome">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../assets/images/slides/slide3.jpg" alt="Second slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../assets/images/slides/slide4.jpg" alt="Second slide">
    </div>
  </div>

  <!-- Left and right controls/icons -->
  <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span class="carousel-control-next-icon"></span>
  </button>
</div>

<div class="container">
	<div class="row">
		<div class="col-sm-6 home-logo">
			<img src="../assets/images/mlcc_logo.jpg" alt="MLCC Logo">
		</div>
		<div class="col-sm-6 home-content">
			<h2>Welcome</h2>
      <div style="border:solid #000 1px;padding: 10px;margin-bottom: 20px;font-weight:bold;">
      <img src="../assets/images/kit/kit-promo.jpg" style="width: 80%; margin-left:10%;" alt="kit store"><br><br>
      <p>We have just opened our Web Store again! the Black Chrome kit store will be open until midnight next Thursday 3rd of August.Click the link below:<br><a href="https://blackchrome.com.au/shop/murraylands-cycling-club/" target="new">https://blackchrome.com.au/shop/murraylands-cycling-club/</a><br>
      Go to *Kit Orders* on the main menu for further details.</p>
      </div>
			<p>The Murraylands Cycling Club (MCC) was established in 2021 and our Vision is to be a Successful Club that has a Sustainable Cycling Culture within the community. </p>
      <p>Our Mission is to Promote cycling and fitness in our community, support and develop cycling in all genres, aspire to be leaders in the cycling world, operate by prioritising safety and well-being, ensure all members of the community are included, assist new and young members with development on their life aims, achieve financial security and Collaborate with organisations of like type.</p>
		</div>
	</div>
</div>

<!-- newsletter -->
<div class="newsletter">
	<div class="container">
		<div class="news">

      <div>
        <transition name="fade" mode="out-in">
          <div v-if="sent" class="sent">
              <p><span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span> &nbsp; &nbsp; &nbsp;
              Thank you for subscribing!</p>
          </div>
        </transition>
      </div>
      <div v-if="!sent" class="container formGroup">
			<div class="news-left">
				<p><span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span> &nbsp; &nbsp; &nbsp;
				Join our Mailing List</p>
			</div>
      <div class="row">
       <form @submit="onSubmit">
          <div class="row">
              <div class="col-md-4">
                <input id="input-name" v-model="form.name" type="text" required placeholder=" Your Name"/>
            </div>
            <div class="col-md-4">
              <input id="input-email" v-model="form.email" type="text" required placeholder=" Email Address"/>
            </div>
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary"> Subscribe </button>
            </div>
          </div>
        </form>
      </div>
      </div>
			<div class="clearfix"> </div>
		</div>
	</div>
</div>
<!-- //newsletter -->

<div class="hwrap"><div class="hmove">
  <div class="hitem">
    <a href="https://www.uci.org/" target="new"><img src="../assets/images/sponsors/uci.png"></a></div>
  <div class="hitem">
    <a href="https://www.auscycling.org.au/" target="new"><img src="../assets/images/sponsors/auscycling.png"></a></div>
  <div class="hitem">
    <a href="https://www.auscycling.org.au/sa" target="new"><img src="../assets/images/sponsors/cyclingsa.png"></a></div>
    <div class="hitem">
      <a href="http://www.adelaidehillscc.org.au/" target="new"><img src="../assets/images/sponsors/adelaidehills.png"></a></div>
  <div class="hitem">
    <a href="https://www.pacc.org.au/" target="new"><img src="../assets/images/sponsors/pacc.png"></a></div>
  <div class="hitem">
    <a href="https://southcoastcycling.org.au/" target="new"><img src="../assets/images/sponsors/sccc.png"></a></div>
  <div class="hitem">
    <a href="https://www.norwoodcc.com.au/" target="new"><img src="../assets/images/sponsors/ncc.png"></a></div>
  <div class="hitem">
    <a href="http://kilkennycc.org.au/#home" target="new"><img src="../assets/images/sponsors/kilkennycc.png"></a></div>
  <div class="hitem">
    <a href="https://www.centrals.org.au/" target="new"><img src="../assets/images/sponsors/centralcc.png"></a></div>
  <div class="hitem">
    <a href="https://mtgambiercycling.com.au/index.aspx" target="new"><img src="../assets/images/sponsors/mgcc.png"></a></div>
  <div class="hitem">
    <a href="https://www.whyallacc.org.au/" target="new"><img src="../assets/images/sponsors/wyallacc.png"></a></div>
</div>
  </div>
</template>
<script>
import UserService from "../services/user.service";
import axios from 'axios';
const querystring = require("querystring");

export default {
  name: "HomeView",
  data() {
    return {
      content: "",
      sent: false,
      form: {
        name: "",
        email: "",
      }
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        axios
          .post(
             "https://www.murraylandscc.org.au/php/subscribe.php",
              querystring.stringify(this.form)
          )
          .then(res => {
             console.log(res);
             this.sent = true;
          });
      }
    }
};
</script>

<style scoped>

.carousel-item {
	width: 100%;
	max-height: 700px;
}

.home-content {
	padding: 20px;
	padding-top: 50px;
}
.home-content h2 {
	color: #2d96ff;
	font-style: italic;
	margin-bottom: 30px;
	font-weight: bold;
}
.home-content p {
	color: #777;
	font-size: 0.9rem;
	letter-spacing: 0.15rem;
	line-height: 1.5rem;
	margin-bottom: 30px;
}
.home-logo {
	padding: 20px;
	padding-top: 50px;
}
.home-logo img {
	width: 100%;
}

/*-- newsletter --*/
.newsletter{
	background:url(../assets/images/bikelane.jpg) no-repeat center fixed;
	background-size:cover;
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
	-ms-background-size:cover;
	min-height:300px;
	padding: 40px;
}
.news {
	margin-top: 100px;
	padding:0.5em 0.5em;
	background:rgb(100,100,100,0.8);
}
.news-left{
	float:left;
	width: 30%;
}
.news-left p{
	font-size:1.5em;
	color:#d0e7fd;
	margin:4px 0 0;
	text-align:center;
}
.news-right{
	float:right;
	width: 65%;
}
.news-right input[type="text"]{
	outline:none;
	color:#000;
	font-size:13px;
	width:30%;
	padding:5px 5px;
	background:#fff;
	border:1px solid #999;
	margin-right: 40px;
}
.form-control {
	width: 90%;outline:none;
	color:#000;
	font-size:13px;
	padding:5px 5px;
	background:#fff;
	border:1px solid #999;
}
.submitbtn {
	background:#2d96ff;
	width:90%;
	color:#fff;
	font-size:13px;
	padding:5px 5px;
	border:none;
	outline:none;
	margin: 0 0 0 2em;
	transition:.5s all;
	-webkit-transition:.5s all;
	-moz-transition:.5s all;
	-o-transition:.5s all;
	-ms-transition:.5s all;
}
.submitbtn:hover {
	background:#1f4496;
}
.news-right input[type="email"]{
	outline:none;
	color:#000;
	font-size:13px;
	width:30%;
	padding:5px 5px;
	background:#fff;
	border:1px solid #999;
}
.news-right input[type="submit"]{
	background:#2d96ff;
	width:30%;
	color:#fff;
	font-size:13px;
	padding:5px 0;
	border:none;
	outline:none;
	margin: 0 0 0 2em;
	transition:.5s all;
	-webkit-transition:.5s all;
	-moz-transition:.5s all;
	-o-transition:.5s all;
	-ms-transition:.5s all;
}
.news-right input[type="submit"]:hover{
	background:#1f4496;
}
.sent {
  color: #fff;
  font-weight: bold;
}
/*-- //newsletter --*/

/* (A) FIXED WRAPPER */
.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #eee;
}

/* (B) MOVING TICKER WRAPPER */
.hmove { display: flex; }

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  flex-shrink: 0;
  width: 40%;
  box-sizing: border-box;
  padding-top: 40px;
	padding-bottom: 50px;
  text-align: center;
}
.hitem img {
	width: 50%;
}

/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
/* 4 ITEMS -400%, CHANGE THIS IF YOU ADD/REMOVE ITEMS */
@keyframes tickerh {
  0% { transform: translate3d(100%, 0, 0); }
  100% { transform: translate3d(-350%, 0, 0); }
}
.hmove { animation: tickerh linear 15s infinite; }
.hmove:hover { animation-play-state: paused; }


</style>

